import { Progress } from "@vwfs-bronson/bronson-react";
import classNames from "classnames";
import { useCallback } from "react";
import { Accept, FileRejection, useDropzone } from "react-dropzone";
import { UploadItemState, UploadItemType } from "./types";

interface UploadItemProps {
  fileName: string;
  type: UploadItemType;
  state: UploadItemState;
  fileSizeProgress: string;
  progress: number;
  onCancel?: () => void;
  onView?: () => void;
  onDelete?: () => void;
  message?: string;
}

const UploadItem = (props: UploadItemProps) => {
  const { fileName, type, state, fileSizeProgress, progress, onCancel, onView, onDelete, message } = props;

  const showSuccessIcon = state === UploadItemState.success;
  const showErrorIcon = state === UploadItemState.error;

  return (
    <div className={`c-upload-item ${state}`}>
      <i className={`c-upload-item__icon c-icon c-icon--[${type}]`} />
      <div className="c-upload-item__content">
        <div className="c-upload-item__title-row">
          <span className="c-upload-item__title">{fileName}</span>
          <div className="c-upload-item__interaction-icons">
            {onCancel && <button className="c-upload-item__interaction-icon c-icon c-icon--small c-icon--[cross]" type="button" onClick={onCancel} aria-label="Abbrechen" />}
            {onView && <button className="c-upload-item__interaction-icon c-icon c-icon--small c-icon--[eye]" type="button" onClick={onView} aria-label="Anzeigen" />}
            {onDelete && <button className="c-upload-item__interaction-icon c-icon c-icon--small c-icon--[bin]" type="button" onClick={onDelete} aria-label="Löschen" />}
            {(showSuccessIcon || showErrorIcon) && <i className="c-upload-item__status-icon" />}
          </div>
        </div>
        <div className="c-upload-item__progress">
          <Progress value={`${progress}`} success={state === UploadItemState.success} error={state === UploadItemState.error} />
        </div>
        <p className="c-upload-item__subtitle-row">
          {message ? <span className="c-upload-item__message">{message}</span> : <span className="c-upload-item__filesize-progress">{fileSizeProgress}</span>}
        </p>
      </div>
    </div>
  );
};

interface UploadProps {
  disabled: boolean;
  multiple: boolean;
  descriptionText?: string;
  descriptionSupplementaryText?: string;
  buttonText?: string;
  disabledText?: string;
  onUpload?: (files: File[]) => void;
  validMimeTypes?: string[];
}

export const Upload: React.FC<UploadProps> & {
  Item: typeof UploadItem;
} = (props) => {
  const { disabled, multiple, descriptionText, descriptionSupplementaryText, buttonText, disabledText, onUpload, validMimeTypes } = props;

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (onUpload) {
        onUpload([...acceptedFiles, ...fileRejections.map((rejection) => rejection.file)]);
      }
    },
    [onUpload],
  );

  const accept =
    validMimeTypes?.reduce((a, mimeType) => {
      a[mimeType] = [];
      return a;
    }, {} as Accept) ?? {};

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple,
    disabled,
    accept,
  });

  return (
    <div
      className={classNames("c-upload", {
        "is-disabled": disabled,
      })}
      {...getRootProps()}
    >
      <input className="c-upload__input" {...getInputProps()} disabled={disabled} />

      {descriptionText && (
        <span className="c-upload__description">
          <i className="c-upload__icon" aria-hidden="true" />
          <span className="c-upload__description-text">{descriptionText}</span>
          {descriptionSupplementaryText && <span className="c-upload__description-supplementary-text">{descriptionSupplementaryText}</span>}
        </span>
      )}

      {buttonText && (
        <span className="c-upload__actions">
          <span className="c-upload__button c-btn">
            <span>{buttonText}</span>
          </span>
        </span>
      )}

      {disabledText && (
        <span className="c-upload__disabled">
          <i className="c-upload__disabled-icon" aria-hidden="true" />
          <span className="c-upload__disabled-text">{disabledText}</span>
        </span>
      )}
    </div>
  );
};

Upload.Item = UploadItem;
