import { SectionHeading } from "@vwfs-bronson/bronson-react";

interface Props {
  errorMessage: string;
}

export const GeneralError = ({ errorMessage }: Props) => (
  <>
    <SectionHeading level={1}>Leider ist ein Fehler aufgetreten.</SectionHeading>

    <p>{errorMessage}</p>

    <p>
      <strong>Bitte versuchen Sie es später noch einmal.</strong>
    </p>
  </>
);
