import { isTestStage } from "@common/env";
import { FG } from "./festgeld";
import { FORMS } from "./forms";
import { GIRO } from "./giro";
import { IDLI } from "./ladeinfrastruktur";
import { PKB } from "./pkbusiness";
import { PG } from "./playground";
import { PK } from "./pluskonto";
import { PKMDJ } from "./pluskonto-mdj";
import { CC } from "./rahmenkredit";
import { DCC } from "./ratenkredit";
import { SB } from "./sparbrief";
import { SK } from "./sparkonto";
import { VISA } from "./visa";

export const PRODUCTS = [GIRO, PK, DCC, CC, SB, SK, VISA, IDLI, FG, PKB, FORMS, ...(isTestStage() ? [PKMDJ, PG] : [])];
