/* eslint-disable react-refresh/only-export-components */
import React, { PropsWithChildren, useCallback, useReducer } from "react";

interface Footnotes {
  [text: string]: {
    index: number;
    usages: number;
  };
}

type FootnoteAction = { type: "addFootnoteOccurrence"; payload: string } | { type: "removeFootnoteOccurrence"; payload: string };

const footnotesReducer = (previousState: Footnotes, action: FootnoteAction): Footnotes => {
  switch (action.type) {
    case "addFootnoteOccurrence": {
      const footnote = previousState[action.payload];
      return {
        ...previousState,
        [action.payload]: {
          ...footnote,
          index: footnote ? footnote.index : Object.keys(previousState).length + 1,
          usages: footnote ? footnote.usages + 1 : 1,
        },
      };
    }
    case "removeFootnoteOccurrence": {
      const footnote = previousState[action.payload];
      return {
        ...previousState,
        [action.payload]: {
          ...footnote,
          usages: footnote.usages - 1,
        },
      };
    }
    default:
      return previousState;
  }
};

const useFootnoteState = () => {
  const [footnotes, dispatch] = useReducer(footnotesReducer, {});

  const addFootnoteOccurrence = useCallback((text: string) => {
    dispatch({ type: "addFootnoteOccurrence", payload: text });
  }, []);

  const removeFootnoteOccurrence = useCallback((text: string) => {
    dispatch({ type: "removeFootnoteOccurrence", payload: text });
  }, []);

  return { footnotes, addFootnoteOccurrence, removeFootnoteOccurrence };
};

const FootnotesContext = React.createContext<ReturnType<typeof useFootnoteState>>({} as ReturnType<typeof useFootnoteState>);

export const FootnotesProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return <FootnotesContext.Provider value={useFootnoteState()}>{children}</FootnotesContext.Provider>;
};

export const useFootnotes = () => React.useContext(FootnotesContext);
