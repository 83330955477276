import { ContentSection } from "@vwfs-bronson/bronson-react";
import { PropsWithChildren } from "react";
import { PageWrapSize } from "../bronson";
import { PageFooter } from "./PageFooter";
import { PageHeader, PageHeaderProps } from "./PageHeader";

interface Props extends PageHeaderProps {
  pageWrapSize?: PageWrapSize; // Defaults to xsmall
  contentSectionClassName?: string; // Defaults to u-pt
}

export const CommonPage: React.FC<PropsWithChildren<Props>> = ({ children, pageWrapSize, contentSectionClassName, ...pageHeaderProps }) => (
  <>
    <PageHeader {...pageHeaderProps} />

    <main className="o-main">
      <ContentSection pageWrapSize={pageWrapSize ?? "xsmall"} className={contentSectionClassName ?? "u-pt"}>
        <ContentSection.ComponentWrapper>{children}</ContentSection.ComponentWrapper>
      </ContentSection>
    </main>

    <PageFooter />
  </>
);
