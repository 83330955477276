import { getCookie } from "@common/cookie";
import { ENV } from "@common/env";
import log from "loglevel";
import { useEffect, useRef } from "react";

interface CookieControlCookie {
  optionalCookies?: {
    analytics: "accepted" | "revoked";
    personalization: "accepted" | "revoked";
    marketing: "accepted" | "revoked";
  };
}

const ADOBE_SCRIPT_PROD = "https://assets.adobedtm.com/launch-ENa9a65fa9c48643a882ed269ce8d91f5d.min.js";
const ADOBE_SCRIPT_TEST = "https://assets.adobedtm.com/launch-EN2b862102842e4b179801c7e48cf8ecd6-development.min.js";

export const AdobeTracking = () => {
  const scriptElementRef = useRef<HTMLScriptElement>(undefined);

  const scriptSrc = ENV.stage === "prod" ? ADOBE_SCRIPT_PROD : ADOBE_SCRIPT_TEST;

  const isAccepted = (cookieControl: CookieControlCookie) =>
    [cookieControl.optionalCookies?.analytics, cookieControl.optionalCookies?.personalization, cookieControl.optionalCookies?.marketing].some((cookie) => cookie === "accepted");

  useEffect(() => {
    const cookieControlCookie = getCookie("CookieControl");
    log.debug("Checking CC cookie...");
    if (cookieControlCookie) {
      const cookieControl: CookieControlCookie = JSON.parse(cookieControlCookie);

      const shouldLoad = isAccepted(cookieControl);

      log.debug("CC cookie is available");
      if (shouldLoad) {
        log.debug("Adobe Analytics is accepted, adding script tag...");
        const script = document.createElement("script");
        script.src = scriptSrc;
        script.async = true;
        script.onload = () => {
          log.debug("Adobe script loading finished, approving analytics...");
          window.adobe?.optIn?.approve(window.adobe.OptInCategories.ECID);
          window.adobe?.optIn?.approve(window.adobe.OptInCategories.ANALYTICS);
        };
        document.body.appendChild(script);
        scriptElementRef.current = script;
      } else {
        log.debug("Adobe Analytics is not accepted");
      }
    } else {
      log.debug("CC cookie is not available");
    }

    document.addEventListener("adobeTrackingConsentChange", (evt: Event) => {
      log.debug("Adobe Analytics consent changed");
      if (evt instanceof CustomEvent) {
        const accepted = evt.detail?.accepted ?? false;
        if (!accepted) {
          log.debug("Adobe Analytics is not accepted, denying analytics...");
          window.adobe?.optIn?.deny(window.adobe.OptInCategories.ECID);
          window.adobe?.optIn?.deny(window.adobe.OptInCategories.ANALYTICS);
        }
        if (scriptElementRef.current) {
          log.debug("Removing script tag");
          if (scriptElementRef.current.parentNode === document.body) {
            document.body.removeChild(scriptElementRef.current);
          }
          scriptElementRef.current = undefined;
        }
        if (accepted) {
          log.debug("Adobe Analytics is accepted, adding script tag...");
          const script = document.createElement("script");
          script.src = scriptSrc;
          script.async = true;
          script.onload = () => {
            log.debug("Adobe script loading finished, approving analytics...");
            window.adobe?.optIn?.approve(window.adobe.OptInCategories.ECID);
            window.adobe?.optIn?.approve(window.adobe.OptInCategories.ANALYTICS);
          };
          document.body.appendChild(script);
          scriptElementRef.current = script;
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
